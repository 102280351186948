@charset "utf-8";

@import 'mixin';
@import 'reset';
@import 'variable';

/* width */
::-webkit-scrollbar {
    width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eeeeee !important; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #851B06 !important; 
    transition: $animate !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #851B06 !important;  
}

html {
    scroll-behavior: smooth;
}

body {
	margin: 0;
    padding: 0;
    background-color: $white;
    font-family: $fontGlacial;
	color: $primary;
	@include box-sizing(border-box);
    overflow-x: hidden;
    font-size: 16px;
    position: relative;
    font-feature-settings: "palt";
}

#AllBox {
    @include transition(all .5s);
}

.only-lg-down {
    @media (min-width: 1025px) {
        display: none;
    }
}

.only-lg {
    @media (max-width: 1024px) {
        display: none;
    }
}

/* HEADER */
@import 'component/header.scss';

/* FOOTER */
@import 'component/footer.scss';

/* MAINVISUAL */
@import 'component/mainvisual.scss';

/* BREADCRUMB */
@import 'component/breadcrumb.scss';

/* COOKIE */
@import 'component/cookie.scss';

#main {


    &.error {
        .firstRow {
            padding: 100px 0 80px;
            position: relative;
            background-color: #F8F5F2;
            text-align: center;

            @media screen and (max-width: 1600px) {
                padding: 60px 0 80px;
            }

            @media screen and (max-width: 1000px) {
                padding: 40px 0 60px;
            }

            @media screen and (max-width: 767px) {
                padding: 30px 0 40px;
            }

            .inner {
                h2 {
                    font-size: 50px;
                    margin-bottom: 15px;
                }
                p {
                    font-size: 20px;
                }
            }
        }
    }
}

.float-btn {
    position: fixed;
    right: 15px;
    bottom: 15px;
    z-index: 10;
    width: 175px;
    cursor: pointer;
    animation: bounce 2s infinite;
    @media screen and (max-width: 767px) {
        right: 5px;
        bottom: 5px;
        width: 125px;
    }
    img {
        animation: fadeInUpBtn 2s infinite;
    }
}

#form-customer {
    .modal-dialog {
        height: calc(100vh - 3rem);
        margin: 1.5rem;
        @media screen and (min-width: 576px) {
            max-width: 768px;
            height: calc(100vh - 3.5rem);
            margin: 1.75rem auto;
        }
        .modal-content {
            height: 100%;
            position: relative;
            .close {
                position: absolute;
                right: -40px;
                top: -25px;
                z-index: 3;
                opacity: 1;
                color: #FFF;
                font-size: 4rem;
                line-height: 1;
                @media screen and (max-width: 767px) {
                    right: -25px;
                    font-size: 3.5rem;
                }
            }
            .modal-body {
                padding: 0;
                iframe {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

.mini-info {
    position: absolute;
    bottom: 30px;
    width: 100%;
    z-index: 1;
    @media screen and (max-width: 950px) {
        bottom: auto;
        position: relative;
        padding-top: 30px;
        &.none-sp {
            display: none;
        }
    }
    &.only-sp {
        display: none;
        @media screen and (max-width: 950px) {
            display: block;
        }
    }
    .inner {
        display: flex;
        justify-content: space-between;
        max-width: 950px;
        width: 100%;
        margin: auto;
        @media screen and (max-width: 950px) {
            padding: 0 5%;
            flex-wrap: wrap;
        }
        div {
            @media screen and (max-width: 950px) {
                width: 47.5%;
                text-align: center;
                padding: 15px 0;
            }
            h3 {
                font-size: 26px;
                font-weight: bold;
                @media screen and (max-width: 950px) {
                    font-size: 22px;
                }
                @media screen and (max-width: 560px) {
                    font-size: 20px;
                }
                @media screen and (max-width: 400px) {
                    font-size: 20px;
                }
            }
            p {
                font-size: 16px;
                font-family: $fontQuicksand;
                @media screen and (max-width: 560px) {
                    font-size: 12px;
                }
                @media screen and (max-width: 400px) {
                    font-size: 10px;
                }
            }
        }
    }
}

#exposure {
    padding: 120px 0 150px;
    @media screen and (max-width: 1200px) {
        padding: 4.5rem 0 100px;
    }
    @media screen and (max-width: 767px) {
        padding: 3rem 0;
    }
    .title-section {
        margin-bottom: 2.5rem;
        @media screen and (max-width: 767px) {
            margin-bottom: 0 !important;
        }
    }
    .flex {
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: 767px) {
            justify-content: space-around;
            flex-wrap: wrap;
        }
        .item {
            width: 20%;
            display: flex;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 767px) {
                width: 33%;
                margin-top: 2.5rem;
            }
            a {
                img {
                    width: 75%;
                    margin: auto;
                    display: block;
                    filter: grayscale(100%);
                    transition: $animate;
                }
                &:hover {
                    img {
                        filter: grayscale(0);
                    }
                }
            }
            &.mediaIndonesia {
                a {
                    img {
                        width: 70%;
                    }
                }
            }
            &.investor,
            &.republika,
            &.liputan6 {
                a {
                    img {
                        width: 80%;
                    }
                }
            }
            &.sindo {
                a {
                    img {
                        width: 85%;
                    }
                }
            }
            &.tribun,
            &.kompas {
                a {
                    img {
                        width: 95%;
                    }
                }
            }
        }
        &.bottom {
            justify-content: center;
            .item {
                margin-top: 3rem;
            }
        }
    }
}