@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

@keyframes iconhop {
	0% {transform: translateY(0%) scaleY(1);}
	5% {transform: translateY(0%) scaleY(0.9);}
	50% {transform: translateY(-20%) scaleY(1.1);}
	10% {transform: translateY(0%) scaleY(0.9);}
	100% {transform: translateY(0%) scaleY(1);}
}


/* SASS UNTUK MAINVISUAL */

#mainvisual {
    position: relative;

    @media screen and (max-width: 950px) {
        margin-top: 75px;
    }

    @media screen and (max-width: 767px) {
        margin-top: 60px;
    }
    
    #btn-mv {
        position: absolute;
        right: 0;
        bottom: 15%;
        width: 150px;
        cursor: pointer;
        transition: $animate;
        z-index: 3;
        &:hover {
            right: -15px;
        }
    }

    &.new-mv {
        width: 100%;
        margin-top: 75px;
        background-image: url(../img/bg-mv2.svg);
        position: relative;
        .inner {
            padding: 1.2% 0 0;
            &.all {
                display: none;
                padding: 0 0 2.1%;
                max-width: 90.5%;
                margin-left: 5.45%;
                .item {
                    width: 100%;
                    &.follower {
                        width: 80.51%;
                        margin-left: 2%;
                    }
                }
            }
            .headline-mv {
                text-align: center;
                margin: 3.41% 0 0;
                padding-bottom: 6.5%;
                font-size: 6.37vw;
                font-weight: bold;
                color: $color-brand;
                animation: fadeInUp;
                animation-duration: 1s;
                &.none {
                    display: none;
                }
                &.block {
                    display: block;
                }
            }
            .desc-character {
                display: none;
                width: 100%;
                text-align: center;
                z-index: 2;
                position: relative;
                &.show {
                    display: block;
                    animation: zoomInDown;
                    animation-duration: .75s;
                }
                &.desc-challanger,
                &.desc-innovator {
                    width: 73.4%;
                    margin: auto;
                }
                &.desc-follower {
                    img {
                        width: 56.3%;
                    }
                }
                &.desc-challanger {
                    margin-top: 2%;
                    padding-bottom: 1.47%;
                }
                &.desc-innovator {
                    margin-top: 2.4%;
                    padding-bottom: 0.6%;
                }
                &.desc-follower {
                    margin-top: 1.5%;
                    padding-bottom: 4.01%;
                }
                .plane {
                    position: absolute;
                    right: 8%;
                    top: 2%;
                    width: 5.7%;
                    animation: fadeOutRight 1.5s infinite;

                }
                .link1 {
                    position: absolute;
                    left: 17%;
                    top: 3%;
                    width: 5.8%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link2 {
                    position: absolute;
                    left: 33%;
                    top: 3.5%;
                    width: 9.3%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link3 {
                    position: absolute;
                    left: 51.5%;
                    top: 3.5%;
                    width: 8.6%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link4 {
                    position: absolute;
                    left: 69.5%;
                    top: 2.5%;
                    width: 8.1%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link5 {
                    position: absolute;
                    left: 13%;
                    top: 12.5%;
                    width: 14%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link6 {
                    position: absolute;
                    left: 31%;
                    top: 7.5%;
                    width: 12.2%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link7 {
                    position: absolute;
                    left: 49%;
                    top: -2%;
                    width: 12.95%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
                .link8 {
                    position: absolute;
                    left: 70%;
                    top: 4%;
                    width: 8.2%;
                    &:hover {
                        animation: iconhop 0.3s ease;
                    }
                }
            }
            .list {
                display: flex;
                justify-content: center;
                align-items: flex-start;
                position: relative;
                height: 32vw;
                .wrapper-mv {
                    position: absolute;
                    top: 0;
                    transition: $animate;
                    .icon {
                        cursor: pointer;
                        position: relative;
                        img {width: 100%;}
                        &:hover {
                            animation: pulse;
                            animation-duration: 1s;
                        }
                    }
                    &.innovator {
                        width: 39.4%;
                        left: 32.8%;
                        .icon {
                            .goal2 {
                                width: 30%;
                                left: 0;
                                top: 5%;
                                position: absolute;
                                transform: rotate(-5deg);
                                transition: $animate;
                            }
                        }
                        &.no-active {
                            width: 33.5%;
                            left: 3.6%;
                            margin-top: -0.5%;
                            .icon {
                                .goal2 {
                                    width: 25%;
                                    top: 10%;
                                    left: 0;
                                }
                            }
                        }
                        &.no-active1 {
                            width: 33%;
                            left: 67.5%;
                            margin-top: 1%;
                            .icon {
                                .goal2 {
                                    width: 25%;
                                    top: 10%;
                                    left: 0;
                                }
                            }
                        }
                    }
                    &.challanger {
                        width: 29.6%;
                        margin-top: 2.2%;
                        left: 4.25%;
                        &.click {
                            width: 34.85%;
                            left: 33.6%;
                            .icon {
                                .goal1 {
                                    width: 35%;
                                    left: -5%;
                                    top: -5%;
                                }
                            }
                        }
                        .icon {
                            .goal1 {
                                width: 27.5%;
                                position: absolute;
                                left: 0%;
                                top: 5%;
                                transform: rotate(-5deg);
                                transition: $animate;
                            }
                        }
                    }
                    &.follower {
                        width: 26.25%;
                        margin-top: 3.5%;
                        right: 4.25%;
                        &.click {
                            width: 30.75%;
                            right: 34.4%;
                            .icon {
                                .goal3 {
                                    width: 35%;
                                    left: -10%;
                                    top: -10%;
                                }
                            }
                        }
                        .icon {
                            .goal3 {
                                width: 30%;
                                position: absolute;
                                left: -5%;
                                top: 0%;
                                transform: rotate(-5deg);
                                transition: $animate;
                            }
                        }
                    }
                }
            }
        }
    }

    &.topPage {
        position: relative;

        .headline-section {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .cont {
                margin: 0 auto;
                .inner {
                    padding-top: 5%;
                    @media screen and (max-width: 1200px) {
                        position: relative;
                    }
                    @media screen and (max-width: 950px) {
                        padding-top: 0;
                    }
                    .headline {
                        @media screen and (max-width: 950px) {
                            width: 70%;
                        }
                        @media screen and (max-width: 767px) {
                            position: relative;
                            width: 100%;
                            z-index: 2;
                        }
                        .tag {
                            color: $primary;
                            display: inline-block;
                            font-size: 17px;
                            font-weight: bold;
                            margin-bottom: 10px;
                            @media screen and (max-width: 950px) {
                                font-size: 15px;
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 14px;
                                margin-bottom: 5px;
                            }
                        }
                        h1,
                        h2 {
                            color: $color-brand;
                            font-size: 59px;
                            font-weight: bold;
                            line-height: 1.1;
                            margin-bottom: 25px;
                            @media screen and (max-width: 1200px) {
                                font-size: 50px;
                            }
                            @media screen and (max-width: 950px) {
                                font-size: 45px;
                                br {
                                    display: none;
                                }
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 30px;
                                margin-bottom: 15px;
                            }
                            @media screen and (max-width: 350px) {
                                font-size: 26px;
                            }
                        }
                        .desc {
                            font-family: $fontQuicksand;
                            font-size: 18px;
                            margin-bottom: 31px;
                            @media screen and (max-width: 1200px) {
                                font-size: 16px;
                            }
                            @media screen and (max-width: 950px) {
                                font-size: 15px;
                            }
                            @media screen and (max-width: 767px) {
                                font-size: 14px;
                                margin-bottom: 15px;
                            }
                            @media screen and (max-width: 400px) {
                                font-size: 13px;
                            }
                            @media screen and (max-width: 350px) {
                                font-size: 12px;
                            }
                        }
                        .more {
                            a {
                                font-weight: normal;
                                font-size: 16px;
                                transition: $animate; 
                                width: 150px;
                                height: 45px;
                                color: #FFF;
                                background-color: #D2002D;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                border-radius: 5px;
                                @media screen and (max-width: 767px) {
                                    font-size: 14px;
                                    width: 140px;
                                    height: 38px;
                                }
                            }
                        }
                    }
                    .headline-img {
                        position: absolute;
                        top: 5%;
                        right: 3%;
                        width: 45%;
                        @media screen and (max-width: 1200px) {
                            width: 50%;
                            right: 0;
                            top: 7.5%;
                        }
                        @media screen and (max-width: 767px) {
                            width: 60%;
                            top: 40%;
                            transform: translate(0, -50%);
                        }
                    }
                }
            }
        }

        .gallery-top {
            .swiper-wrapper {
                .swiper-slide {
                    width: 100%;
                    height: 100vh;
                    background-color: #FFF;
                    padding: 75px 0;
                    background-image: url(../img/bg-mv.svg);
                    background-size: cover;
                    background-position: center;
                    background-repeat: no-repeat;
                    @media screen and (max-width: 950px) {
                        height: auto;
                        padding: 60px 0;
                    }
                    @media screen and (max-width: 767px) {
                        padding: 40px 0;
                    }
                    &.slide2 {
                        background-image: url(../img/bg-mv1.svg);
                        background-size: cover;
                        background-position: left;
                        background-repeat: no-repeat;
                        .headline-section {
                            .inner {
                                position: relative;
                                .headline {
                                    .desc {
                                        .garansi {
                                            margin-top: 15px;
                                            color: $color-brand;
                                            font-weight: bold;
                                            h4 {
                                                display: inline-block;
                                                border: 2px solid $color-brand;
                                                font-weight: bold;
                                                color: $color-brand;
                                                padding: 5px 8px;
                                                margin-bottom: 5px;
                                                font-size: 20px;
                                                @media screen and (max-width: 1200px) {
                                                    font-size: 18px;
                                                }
                                                @media screen and (max-width: 767px) {
                                                    font-size: 16px;
                                                }
                                            }
                                        }
                                    }
                                }
                                .headline-img {
                                    position: absolute;
                                    top: auto;
                                    bottom: 0;
                                    right: 0;
                                    width: auto;
                                    @media screen and (max-width: 1200px) {
                                        width: 50%;
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    }
                                    @media screen and (max-width: 950px) {
                                        width: 30%;
                                    }
                                    @media screen and (max-width: 767px) {
                                        width: 40%;
                                        bottom: -7%;
                                        transform: unset;
                                    }
                                }
                            }
                        }
                    }
                    &.slide3 {
                        background-image: url(../img/bg-mv2.svg);
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat;
                        .headline-section {
                            .inner {
                                position: relative;
                                .headline-img {
                                    position: absolute;
                                    top: auto;
                                    bottom: 0;
                                    right: 0;
                                    width: auto;
                                    @media screen and (max-width: 1200px) {
                                        width: 50%;
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    }
                                    @media screen and (max-width: 950px) {
                                        width: 30%;
                                    }
                                    @media screen and (max-width: 767px) {
                                        width: 40%;
                                        bottom: -20%;
                                        transform: unset;
                                    }
                                }
                            }
                        }
                    }
                    &.slide4 {
                        background-image: url(../img/bg-mv3.svg);
                        background-size: cover;
                        background-position: top;
                        background-repeat: no-repeat;
                        .headline-section {
                            .inner {
                                position: relative;
                                .headline-img {
                                    position: absolute;
                                    top: auto;
                                    bottom: 0;
                                    right: 0;
                                    width: auto;
                                    @media screen and (max-width: 1200px) {
                                        width: 50%;
                                        display: flex;
                                        justify-content: flex-end;
                                        align-items: center;
                                    }
                                    @media screen and (max-width: 950px) {
                                        width: 30%;
                                    }
                                    @media screen and (max-width: 767px) {
                                        width: 35%;
                                        bottom: -15%;
                                        transform: unset;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.akselerasi-mv {
        position: relative;
        width: 100%;
        height: 100vh;
        background-color: #FFF;
        padding: 125px 0 75px;
        background-image: url(../img/bg-mv1.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        @media screen and (max-width: 950px) {
            height: auto;
            padding: 60px 0;
        }
        @media screen and (max-width: 767px) {
            padding: 40px 0 60px;
        }
        @media screen and (max-width: 400px) {
            padding: 30px 0;
        }
        .headline-section {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .inner {
                position: relative;
                // &.n3 {
                //     display: flex;
                //     justify-content: space-between;
                //     align-items: center;
                // }
                .headline {
                    @media screen and (max-width: 950px) {
                        width: 65%;
                    }
                    @media screen and (max-width: 767px) {
                        position: relative;
                        width: 100%;
                        z-index: 2;
                    }
                    .tag {
                        color: $primary;
                        display: inline-block;
                        font-size: 17px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        @media screen and (max-width: 950px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                    }
                    h1,
                    h2 {
                        color: $color-brand;
                        font-size: 59px;
                        font-weight: bold;
                        line-height: 1.1;
                        margin-bottom: 25px;
                        @media screen and (max-width: 1200px) {
                            font-size: 50px;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 45px;
                            br {
                                display: none;
                            }
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 30px;
                            margin-bottom: 15px;
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 26px;
                        }
                    }
                    .desc {
                        font-family: $fontQuicksand;
                        font-size: 18px;
                        margin-bottom: 31px;
                        @media screen and (max-width: 1200px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 13px;
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 400px) {
                            font-size: 13px;
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 12px;
                        }
                        .garansi {
                            margin-top: 15px;
                            color: $color-brand;
                            font-weight: bold;
                            h4 {
                                display: inline-block;
                                border: 2px solid $color-brand;
                                font-weight: bold;
                                color: $color-brand;
                                padding: 5px 8px;
                                margin-bottom: 5px;
                                font-size: 20px;
                                @media screen and (max-width: 1200px) {
                                    font-size: 18px;
                                }
                                @media screen and (max-width: 767px) {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                    a {
                        font-weight: 500;
                        font-size: 16px;
                        transition: $animate; 
                        width: 150px;
                        height: 45px;
                        color: #FFF;
                        background-color: #D2002D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            width: 140px;
                            height: 38px;
                        }
                    }
                }
                .headline-img {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    right: 0;
                    width: auto;
                    @media screen and (max-width: 1200px) {
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    @media screen and (max-width: 950px) {
                        width: 30%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 40%;
                        bottom: -7.5%;
                        transform: unset;
                    }
                    &.otodidak {
                        width: 350px;
                        @media screen and (max-width: 1200px) {
                            width: 35%;
                        }
                        @media screen and (max-width: 950px) {
                            width: 40%;
                        }
                        @media screen and (max-width: 767px) {
                            width: 40%;
                            bottom: 0;
                        }
                    }
                    &.n3 {
                        width: 350px;
                        @media screen and (max-width: 1200px) {
                            width: 50%;
                        }
                        @media screen and (max-width: 950px) {
                            width: 30%;
                        }
                        @media screen and (max-width: 767px) {
                            width: 40%;
                        }
                        // position: relative;
                        // width: 50%;
                        // @media screen and (max-width: 1200px) {
                        //     width: 50%;
                        //     display: block;
                        // }
                        // @media screen and (max-width: 950px) {
                        //     width: 60%;
                        // }
                        // @media screen and (max-width: 767px) {
                        //     width: 50%;
                        //     position: absolute;
                        //     bottom: 0;
                        // }
                        // .top {
                        //     margin: auto;
                        //     margin-bottom: 2.5rem;
                        //     width: 50%;
                        //     @media screen and (max-width: 767px) {
                        //         width: 70%;
                        //         margin-bottom: 0;
                        //     }
                        // }
                        // .bottom {
                        //     display: flex;
                        //     justify-content: space-between;
                        //     align-items: center;
                        //     .logo-jclass {
                        //         width: 25%;
                        //     }
                        //     .logo-incul {
                        //         width: 50%;
                        //     }
                        //     .logo-mazii {
                        //         width: 20%;
                        //     }
                        // }
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }

    &.wa-mv {
        position: relative;
        width: 100%;
        height: 100vh;
        background-color: #FFF;
        padding: 125px 0 75px;
        background-image: url(../img/bg-mv2.svg);
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        @media screen and (max-width: 950px) {
            height: auto;
            padding: 60px 0;
        }
        @media screen and (max-width: 767px) {
            padding: 40px 0 60px;
        }
        @media screen and (max-width: 400px) {
            padding: 30px 0;
        }
        .headline-section {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            .inner {
                position: relative;
                @media screen and (max-width: 1200px) {
                    position: relative;
                }
                @media screen and (max-width: 767px) {
                }
                .headline {
                    @media screen and (max-width: 950px) {
                        width: 65%;
                    }
                    @media screen and (max-width: 767px) {
                        position: relative;
                        width: 100%;
                        z-index: 2;
                    }
                    .tag {
                        color: $primary;
                        display: inline-block;
                        font-size: 17px;
                        font-weight: bold;
                        margin-bottom: 10px;
                        @media screen and (max-width: 950px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            margin-bottom: 5px;
                        }
                    }
                    h1,
                    h2 {
                        color: $color-brand;
                        font-size: 59px;
                        font-weight: bold;
                        line-height: 1.1;
                        margin-bottom: 25px;
                        @media screen and (max-width: 1200px) {
                            font-size: 50px;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 45px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 40px;
                        }
                        @media screen and (max-width: 560px) {
                            font-size: 36px;
                        }
                        @media screen and (max-width: 400px) {
                            font-size: 32px;
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 30px;
                        }
                    }
                    .desc {
                        font-family: $fontQuicksand;
                        font-size: 18px;
                        margin-bottom: 31px;
                        @media screen and (max-width: 1200px) {
                            font-size: 16px;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            margin-bottom: 30px;
                        }
                        @media screen and (max-width: 400px) {
                            font-size: 13px;
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 12px;
                        }
                    }
                    a {
                        font-weight: 500;
                        font-size: 16px;
                        transition: $animate; 
                        width: 150px;
                        height: 45px;
                        color: #FFF;
                        background-color: #D2002D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        border-radius: 5px;
                        @media screen and (max-width: 767px) {
                            font-size: 14px;
                            width: 140px;
                            height: 38px;
                        }
                    }
                }
                .headline-img {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    right: 0;
                    width: auto;
                    @media screen and (max-width: 1200px) {
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    @media screen and (max-width: 950px) {
                        width: 30%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 35%;
                        bottom: -10%;
                        transform: unset;
                    }
                }
            }
        }
    }

    &.affiliate-mv {
        position: relative;
        width: 100%;
        background-color: #FFF;
        padding: 125px 0 75px;
        background-image: url(../img/bg-mv-affiliate.svg);
        background-size: cover;
        background-position: left;
        background-repeat: no-repeat;
        @media screen and (max-width: 950px) {
            height: auto;
            padding: 60px 0;
        }
        @media screen and (max-width: 767px) {
            padding: 40px 0 60px;
        }
        @media screen and (max-width: 400px) {
            padding: 30px 0;
        }
        .headline-section {
            width: 100%;
            height: 100%;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .inner {
                position: relative;
                .headline {
                    @media screen and (max-width: 950px) {
                        width: 65%;
                    }
                    @media screen and (max-width: 767px) {
                        position: relative;
                        width: 100%;
                        z-index: 2;
                    }
                    .tag {
                        color: #FFF;
                        display: inline-block;
                        background-color: #F4A16D;
                        font-size: 17px;
                        font-weight: bold;
                        padding: 8px 18px;
                        @include border-radius(50px);
                        margin-bottom: 15px;
                        &.red {
                            background-color: $color-brand;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 15px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 10px;
                            padding: 5px 15px;
                            margin-bottom: 10px;
                        }
                        @media screen and (max-width: 400px) {
                            font-size: 10px;
                        }
                    }
                    h1,
                    h2 {
                        color: $color-brand;
                        font-size: 59px;
                        font-weight: bold;
                        line-height: 1.1;
                        margin-bottom: 24px;
                        @media screen and (max-width: 1200px) {
                            font-size: 50px;
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 45px;
                            br {
                                display: none;
                            }
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 30px;
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 26px;
                        }
                    }
                    .desc {
                        font-size: 43px;
                        font-weight: bold;
                        color: #933132;
                        margin-bottom: 31px;
                        line-height: 1.2;
                        @media screen and (max-width: 1200px) {
                            font-size: 32px;
                        }
                        @media screen and (max-width: 950px) {
                            font-size: 26px;
                        }
                        @media screen and (max-width: 767px) {
                            font-size: 20px;
                            margin-bottom: 20px;
                        }
                        @media screen and (max-width: 400px) {
                            font-size: 18px;
                        }
                        @media screen and (max-width: 350px) {
                            font-size: 15px;
                        }
                    }
                    a {
                        width: 175px;
                        height: 45px;
                        border: 1px solid #D2002D;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: #D2002D;
                        background-color: transparent;
                        font-size: 18px;
                        font-weight: normal;
                        border-radius: 5px;
                        position: relative;
                        transition: $animate;
                        @media screen and (max-width: 767px) {
                            margin: auto;
                        }
                        @media screen and (max-width: 560px) {
                            width: 150px;
                            height: 40px;
                        }
                        span {
                            font-weight: normal;
                            font-size: 18px;
                            color: #D2002D;
                            margin-left: 10px;
                            position: relative;
                            transition: $animate;
                            @media screen and (max-width: 560px) {
                                font-size: 16px;
                            }
                        }
                        &:hover {
                            background-color: #D2002D;
                            span {
                                color: #FFF;
                            }
                        }
                        @media screen and (max-width: 767px) {
                            margin: 0;
                        }
                    }
                }
                .headline-img {
                    position: absolute;
                    top: auto;
                    bottom: 0;
                    right: 0;
                    width: auto;
                    @media screen and (max-width: 1200px) {
                        width: 50%;
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;
                    }
                    @media screen and (max-width: 950px) {
                        width: 30%;
                    }
                    @media screen and (max-width: 767px) {
                        width: 40%;
                        bottom: -7.5%;
                        transform: unset;
                    }
                }
            }
        }
    }

    &.subPage {
        background-color: #fffbfb;
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 950px) {
            height: 225px;
        }
        @media screen and (max-width: 767px) {
            height: 150px;
        }
        .mvText {
            h1 {
                font-weight: bold;
                color: #933132;
                font-size: 42px;
                text-align: center;
                position: relative;
                top: 15px;
                font-family: $fontYuGothic;
                @media screen and (max-width: 767px) {
                    font-size: 32px;
                }
            }
        }
    }
}