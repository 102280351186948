@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

/* SASS UNTUK BREADCRUMB */

/*-----BREADCRUMB-----*/
.breadcrumbPage {
    width: 100%;
    z-index: 1;
    overflow: hidden;
    background-color: white;

    ul {
        padding: 10px 0;
        @include flexbox;
        @include align-items(center);

        li {
            font-size: pxtopercent(14);
            letter-spacing: 1px;
            color: $color-brand;
            word-break: keep-all;

            @media (max-width: 767px) {
                font-size: pxtopercent(12);
            }

            &:not(:last-child) {
                margin-right: 25px;
            }
            a { 
                position: relative;
                img {
                    height: 18px;
                    position: relative;
                    top: -2px;
                }
                &.active {
                    text-decoration: underline;
                }
            }
            &.arrow {
                position: relative;
                &:before {
                    position: absolute;
                    top: 10px;
                    left: -17px;
                    display: block;
                    content: '';
                    height: 6px;
                    width: 6px;
                    border: 1px solid $color-base;
                    border-left-width: 0;
                    border-top-width: 0;
                    -webkit-transform: translate(0, -50%) rotate(-45deg);
                    -ms-transform: translate(0, -50%) rotate(-45deg);
                    transform: translate(0, -50%) rotate(-45deg);
                }
            }
            &.active {
                color: $color-base;
            }
        }
    }
    
}