@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

#header-fixed {
    background-color: transparent;
    width: 100%;
    height: 75px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5;
    transition: $animate;
    padding: 0 0;
    @media screen and (max-width:950px) {
        background-color: $color-brand;
    }
    @media screen and (max-width:767px) {
        height: 60px;
    }
    &.show-btn {
        top: 0;
    }
    .cont {
        @media screen and (max-width: 1200px) {
            max-width: 95%;
        }
        @media screen and (max-width: 950px) {
            max-width: 100%;
        }
    }
    .inner {
        width: 100%;
        height: 75px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media screen and (max-width: 950px) {
            padding-left: 2.5%;
        }
        @media screen and (max-width:767px) {
            height: 60px;
        }
        .logo { 
            width: 200px;
            @media screen and (max-width:767px) {
                width: 150px;
            }
            img {
                width: auto;
                height: 45px;
                transition: $animate;
                @media screen and (max-width:767px) {
                    height: 35px;
                }
                &.white {
                    display: none;
                    @media screen and (max-width:950px) {
                        display: block;
                    }
                }
                &.red {
                    display: block;
                    @media screen and (max-width:950px) {
                        display: none;
                    }
                }
            }
        }
        .iconNav {
            @include flexbox;
            @include flex-direction(column);
            @include justify-content(center);
            @include align-items(center);
            width: 75px;
            height: 75px;
            background-color: transparent;
            transition: all .8s ease;
            display: none;
            @media screen and (max-width: 950px) {
                display: block;
            }
            @media screen and (max-width:767px) {
                width: 60px;
                height: 60px;
            }
            .hamburger {
                padding: 0;
                @include flexbox;
                @include justify-content(center);
                @include align-items(center);
                flex-direction: column;
                position: relative;
                width: 75px;
                height: 100%;
                color: $white;
                transition: all .8s ease;
                @media screen and (max-width:767px) {
                    width: 60px;
                }
                &:focus {
                    outline: none;
                }
                .hamburger-box {
                    width: 35px;
                    height: 26px;
                    display: flex;
                    justify-content: flex-end;
                    @media screen and (max-width:767px) {
                        width: 30px;
                        height: 23px;
                    }
                    .hamburger-inner {
                        @include border-radius(0);
                        width: 35px;
                        height: 1.5px;
                        background-color: $white;
                        transition: all .8s ease;
                        @media screen and (max-width:767px) {
                            width: 30px;
                        }
                        &:before {
                            @include border-radius(0);
                            width: 35px;
                            top: -12px;
                            height: 1.5px;
                            right: 0;
                            background-color: $white;
                            transition: all .8s ease;
                            @media screen and (max-width:767px) {
                                width: 30px;
                                top: -10px;
                            }
                        }
                        &:after {
                            width: 35px;
                            height: 1.5px;
                            right: 0;
                            background-color: $white;
                            transition: all .8s ease;
                            @media screen and (max-width:767px) {
                                width: 30px;
                            }
                        }
                    }
                }
                &.hamburger--collapse {
                    .hamburger-inner {
                        &:after {
                            top: -24px;
                            @media screen and (max-width:767px) {
                                top: -20px;
                            }
                        }
                    }
                }
                &.is-active {
                    &:hover {
                        @include opacity(1);
                    }
                    .hamburger-inner {
                        &:before {
                            top: 0;
                        }
                        &:after {
                            top: 0;
                        }
                    }
                    .textMenu {
                        display: none;
                    }
                    .textClose {
                        display: block;
                    }
                }
            }
            p {
                margin-top: 9px;
                font-size: 9px;
                font-weight: bold;
                font-family: $fontMincho;
                text-align: center;
                letter-spacing: 2px;
            }
        }
        .links {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            @media screen and (max-width: 950px) {
                display: none;
            }
            nav {
                flex: 1;
                ul {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    li {
                        margin: 0 15px;
                        position: relative;
                        @media screen and (max-width: 1200px) {
                            margin: 0 12px;
                        }
                        &:last-child {
                            margin-right: 0;
                        }
                        a {
                            transition: color 0.5s;
                            position: relative;
                            display: block;
                            span {
                                font-size: 18px;
                                color: $color-brand;
                                transition: $animate;
                            }
                            &.btn-register {
                                width: 150px;
                                display: flex;
                                padding: 0;
                                margin-right: 0;
                                background-color: #FFF;
                                transition: $animate;
                                color: $red;
                                span {
                                    font-size: 16px;
                                    color: $red;
                                }
                                // &:hover {
                                //     background-color: $red;
                                //     span {
                                //         color: $white;
                                //     }
                                // }
                            }
                        }
                        ul {
                            display: block;
                            position: absolute;
                            overflow: hidden;
                            opacity: 0;
                            transform: translateY(-8px);
                            visibility: hidden;
                            left: -50px;
                            transition: all .4s ease-in-out, visibility 0s .3s;
                            padding-top: 5px;
                            min-width: 200px;
                            &.sm {
                                min-width: 125px;
                                left: -25px;
                            }
                            li {
                                margin: 0;
                                background-color: #FFF;
                                a {
                                    padding: 8px 15px;
                                    font-size: 15px;
                                    transition: $animate; 
                                    &:hover {
                                        background-color: $color-brand;
                                        color: #FFF;
                                    }
                                }
                            }
                        }
                        &:hover {
                            ul {
                                opacity: 1;
                                visibility: visible;
                                transform: translateY(0);
                                transition: all .4s ease-in-out, visibility 0s;
                            }
                        }
                    }
                }
            }
        }
    }

    &.bg,
    &.headerTop {
        background-color: $color-brand;
        .inner {
            .logo {
                a {
                    img {
                        &.white {
                            display: block;
                            @media screen and (max-width:950px) {
                                display: block;
                            }
                        }
                        &.red {
                            display: none;
                            @media screen and (max-width:950px) {
                                display: none;
                            }
                        }
                    }
                }
            }
            .links {
                nav {
                    ul {
                        li {
                            a {
                                span {
                                    color: #FFF;
                                }
                                &.btn-register {
                                    background-color: $white;
                                    span {
                                        color: $red;
                                    }
                                }
                            }
                            ul {
                                padding-top: 25px;
                                li {
                                    background-color: #FFF;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}

/* NAV SP */
.navSp {
    display: none;
    @media screen and (max-width: 950px) {
        position: fixed;
        top: 75px;
        background-color: white;
        width: 100%;
        height: 100%;
        z-index: 100;
        padding: 0 0 100px;
        overflow-y: auto;
    }
    @media screen and (max-width:767px) {
        top: 60px;
        padding: 0 0 90px;
    }
    .inner {
        width: 100%;
    }
    .top {
        ul.firstRow {
            width: 100%;
            margin-bottom: 30px;
            li {
                width: 100%;
                line-height: 45px;
                border-bottom: 1px dashed #bbbbbb;
                position: relative;

                .linkSp {
                    @include flexbox;
                    @include justify-content (flex-start);
                    @include align-items(flex-end);
                    position: relative;
                    padding: 0 15px;
                    letter-spacing: 1px;
                    font-size: 16px;
                }

                p {
                    padding: 0 15px;
                    line-height: 40px;
                }

                .ddown-sp {
    
                    li {
                        padding: 0 15px;

                        &:last-child {
                            border-bottom: 0;
                        }

                        a {
                            font-weight: normal;
                        }
                    }
                }

                &.hasChild {

                    a {
                        display: inline-block;
                    }

                    i {
                        position: absolute;
                        right: 15px;
                        top: 23px;
                        font-size: 24px;
                        transform: translateY(-50%);
                        transition: all .2s ease-in-out;

                        &._show {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }

                    .ddown-sp {
    
                        li {
                            padding: 0 15px;
    
                            &:last-child {
                                border-bottom: 0;
                            }
    
                            a {
                                font-weight: normal;
    
                                &::before {
                                    display: inline-block;
                                    content: '・';
                                    padding-right: 4px;
                                    transform: translateX(-6px);
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .btn-center {
        a {
            margin: auto;
        }
    }

    .sns {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
        a {
            width: auto;
            margin: 0 15px;
            background-color: transparent;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: $animate;
            .ig {
                height: 24px;
                @media screen and (max-width: 400px) {
                    height: 22px;
                }
            }
            .tiktok {
                height: 24px;
                @media screen and (max-width: 400px) {
                    height: 22px;
                }
            }
            .youtube {
                height: 24px;
                @media screen and (max-width: 400px) {
                    height: 18px;
                }
            }
            .shopee {
                height: 29px;
                @media screen and (max-width: 400px) {
                    height: 24px;
                }
            }
            .tokopedia {
                height: 31px;
                @media screen and (max-width: 400px) {
                    height: 26px;
                }
            }
        }
    }
         
}

// CSS ONLY FOR INTERNET EXPLORER
@media screen and (-ms-high-contrast: active),
(-ms-high-contrast: none) {}