@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

#cto {
    background-color: #FFF2F2;
    padding: 50px 0 60px;
    @media screen and (max-width: 560px) {
        padding: 40px 0 60px;
    }
    .inner {
        h2 {
            text-align: center;
            font-size: 20px;
            font-weight: bold;
            color: $color-brand;
            margin-bottom: 30px;
        }
        div {
            display: flex;
            justify-content: center;
            align-items: center;
            a {
                width: 63px;
                height: 63px;
                margin: 0 6px;
                background-color: #FFF;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: $animate;
                &:hover {
                    background-color: #EEE;
                }
                @media screen and (max-width: 400px) {
                    width: 45px;
                    height: 45px;
                    margin: 0 5px;
                }
                .ig {
                    height: 24px;
                    @media screen and (max-width: 400px) {
                        height: 22px;
                    }
                }
                .tiktok {
                    height: 24px;
                    @media screen and (max-width: 400px) {
                        height: 22px;
                    }
                }
                .youtube {
                    height: 24px;
                    @media screen and (max-width: 400px) {
                        height: 18px;
                    }
                }
                .shopee {
                    height: 29px;
                    @media screen and (max-width: 400px) {
                        height: 24px;
                    }
                }
                .tokopedia {
                    height: 31px;
                    @media screen and (max-width: 400px) {
                        height: 26px;
                    }
                }
            }
        }
    }
}

/* SASS UNTUK FOOTER */
#footer {
    background-color: #933132;
    padding: 50px 0;
    color: #FFF;
    @media screen and (max-width: 767px) {
        padding: 30px 0;
    }
    .inner {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @media screen and (max-width: 767px) {
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
        .leftSide {
            .logo {
                display: inline-block;
                margin-bottom: 10px;
                @media screen and (max-width: 767px) {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                }
                img {
                    height: 45px;
                }
            }
            h2 {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 30px;
                @media screen and (max-width: 767px) {
                    text-align: left;
                    font-size: 16px;
                }
            }
            .information {
                > div {
                    display: flex;
                    justify-content: flex-start;
                    align-items: flex-start;
                    margin-bottom: 10px;
                    font-family: $fontQuicksand;
                    &:last-child {
                        margin-bottom: 0;
                    }
                    .icon {
                        width: 30px;
                        display: flex;
                        img {
                            position: relative;
                            top: 3px;
                            &.email {
                                top: 6px;
                            }
                            &.phone {
                                top: 1px;
                            }
                        }
                        svg {
                            width: 15px;
                            position: relative;
                            top: 3px;
                        }
                    }
                    .text {
                        font-size: 14px;
                        img {
                            vertical-align: middle;
                            height: 15px;
                        }
                    }
                }
            }
        }
        .rightSide {
            @media screen and (max-width: 767px) {
                margin-top: 30px;
            }
            div {
                display: flex;
                justify-content: center;
                align-items: center;
                a {
                    width: 45px;
                    height: 45px;
                    margin: 0 5px;
                    background-color: #FFF;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transition: $animate;
                    &:hover {
                        background-color: #EEE;
                    }
                    .ig {
                        height: 22px;
                    }
                    .tiktok {
                        height: 22px;
                    }
                    .youtube {
                        height: 18px;
                    }
                    .shopee {
                        height: 24px;
                    }
                    .tokopedia {
                        height: 26px;
                    }
                }
            }
        }
    }
}

/* Copyright */
.copyright {
    background-color: #881E1F;
    padding: 10px 15px;
    text-align: center;
    color: #FFF;
    font-size: 11px;
}