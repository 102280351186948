@charset "utf-8";

@import '../mixin';
@import '../variable';
@import '../font';

.cc-window {
    @include box-shadow(0px 0px 16px 0px rgba(0, 0, 0, 0.08) !important); 
    @include border-radius(0);
}

.cc-message a {
    color: $primary !important;
    position: relative;
    text-decoration: underline !important;
    &:hover {
        text-decoration: none !important;
    }
}

.cc-btn {
    @include flexbox;
    @include justify-content(center);
    @include align-items(center);
    width: 318px;
    height: 45px;
    color: #FFF !important;
    background-color: $color-brand !important;
    @include transition(all .5s);
    border: 1px solid $color-brand !important;
    margin: 0;
    position: relative;
    font-size: 18px;
    @include border-radius(56px);
    @media screen and (max-width: 480px) {
        width: 100%;
        &:last-child {
            margin-top: 15px;
        }
    }
    &:hover {
        background-color: mix($color-brand, white, 80%) !important;
    }
}